import React from "react";
import SEO from "../components/seo";
import { Link } from "gatsby";
import "../styles/entry.scss";

const NotFoundPage = () => (
  <div className={"layout"}>
    <div className={"bio"}>
      <SEO title="404: Not found" />
      <h1 className={"heading"}>NOT FOUND</h1>
      <p className={"paragraph"}>
        You just hit a route that doesn&#39;t exist...
      </p>
      <span className={"paragraph"}>
        <Link to="/" className="link vibescout">
          <b>Go Home</b>
        </Link>
      </span>
    </div>
  </div>
);

export default NotFoundPage;
